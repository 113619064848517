import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const theme = {
  spaces: {
    xxs: "40px",
    xs: "62px",
    headerHeight: "25vh",
  },
  colors: {
    darkGrey: "#2b2424",
  },
  breakpoints: {
    xs: "390px",
    s: "480px",
    m: "769px",
    l: "1024px",
    xl: "1200px",
    xxl: "1400px",
  },
};

export const size = {
  xs: `(min-width: ${theme.breakpoints.xs})`,
  s: `(min-width: ${theme.breakpoints.s})`,
  m: `(min-width: ${theme.breakpoints.m})`,
  l: `(min-width: ${theme.breakpoints.l})`,
  xl: `(min-width: ${theme.breakpoints.xl})`,
  xxl: `(min-width: ${theme.breakpoints.xxl})`,
};

export const GlobalStyle = createGlobalStyle`
  ${reset}
  ::selection { background: #2b2424; color: white; }
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    font-family: 'Montserrat', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    color: #555050;
    background-color: white;
  }
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1 {
    font: normal normal 700 30px/40px "Playfair Display", serif;
    margin-bottom: 26px;
    @media ${size.xs} {
      font: normal normal 700 42px/54px "Playfair Display", serif;
    }
    @media ${size.m} {
      font-size: 52px;
      line-height: 69px;
    }
  }
  h2 {
    
  }
  p {
    font: normal normal 400 19px/35px "Playfair Display", serif;
    text-align: left;
    letter-spacing: 0.05em;
  }
  a {
    font: normal normal 500 24px/41px "Montserrat", sans-serif;
    letter-spacing: 0.05em;
    text-decoration: none;
    color: white;
  }

  input, button {
    background: none;
    border: none;
    outline: none;
  }
  input:focus, button:focus {
    background: none;
  }
`;
