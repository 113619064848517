import React, { useState } from "react";
export const myContext = React.createContext();

const Provider = (props) => {
  // const [currentLanguage, setCurrentLanguage] = useState("en");
  const [isMenuOpened, set_isMenuOpened] = useState(false);
  const [isContactFormOpened, set_isContactFormOpened] = useState(false);
  const [pageSections, set_pageSections] = useState([]);
  const [currentSectionKey, set_currentSectionKey] = useState(1);

  return (
    <myContext.Provider
      value={{
        isMenuOpened,
        set_isMenuOpened,
        isContactFormOpened,
        set_isContactFormOpened,
        currentSectionKey,
        set_currentSectionKey,
        pageSections,
        set_pageSections,
        // isMenuOpened: () => {
        //   isMen
        // }
      }}
    >
      {props.children}
    </myContext.Provider>
  );
};

export default Provider;
