import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "./src/theme/theme";
import StoreProvider from "./src/provider";
export const wrapRootElement = ({ element }) => (
  <StoreProvider>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {element}
    </ThemeProvider>
  </StoreProvider>
);
